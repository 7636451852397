const firebaseConfig = {
	apiKey: 'AIzaSyBcS3cwlUXpK99s0FiNLcdhiTqTbqa8pRo',
	authDomain: 'webs-58a8d.firebaseapp.com',
	projectId: 'webs-58a8d',
	storageBucket: 'webs-58a8d.appspot.com',
	messagingSenderId: '730684174767',
	appId: '1:730684174767:web:29cc6381fbaedf2b0c3f5a',
	measurementId: 'G-SW12Z1V9HT',
}

export default firebaseConfig
